<template>
  <div style="margin:0 20px">
    <div
      class="media-content"
      v-if="
        question != null && question.mediaType != '' && question.mediaUrl != ''
      "
    >
      <div v-if="question.mediaType == 1">
        <img class="full-width" :src="question.mediaUrl" />
      </div>
      <div v-if="question.mediaType == 2">
        <audio width="100%" type="video/mp4" controls>
          <source :src="question.mediaUrl"/>
        </audio>
      </div>
      <div v-if="question.mediaType == 3" class="media">
        <video width="100%" height="100%" type="video/mp4" controls disablepictureinpicture>
          <source :src="question.mediaUrl"/>
        </video>
      </div>
    </div>

    <div class="question">
      <div class="question-margin">
        <quessionnaire-tile :title="question"></quessionnaire-tile>
      </div>

      <div class="question-margin options">
        <van-field
          style="background-color: transparent;"
          v-model="message"
          rows="5"
          autosize
          type="textarea"
          maxlength="1000"
          :placeholder="placCon?placCon:'Please input your options'"
          show-word-limit
        />
      </div>
    </div>
  </div>
</template>

<script>
import quessionnaireTile from "./questionnaireTitle.vue";
export default {
  components: { quessionnaireTile },
  props: { question: {}, list: {} },
  data() {
    return {
      message: "",
      answerDetail: {
        answer: "",
        cascadeDetailCode: "",
        surveyOptionId: ""
      },
      placCon:''
    };
  },
  watch:{
    question:{
      handler (v) {
        console.log(v)
        // const is_page = v.answer && v.answer[0].surveyOptionId;
        // this.answer = is_page;
        // this.value = v.answer && v.answer[0].answer
        if(this.value){
          this.answerDetail = v.answer
        } else{
          this.answerDetail = {
            answer: "",
            cascadeDetailCode: "",
            surveyOptionId: ""
          }
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    answerQuestion() {
      this.answerDetail.answer = this.message;
      this.answerDetail.surveyOptionId = this.message ? this.question.options[0].id : -1;
      this.answerDetail.questionId = this.question.questionId
      let answerDetails = [];
      answerDetails.push(this.answerDetail);
      this.list[this.question.sort - 1] = {
        ...this.question,
        answer: answerDetails
      };
      this.$emit(
        "answerQuestion",
        this.question.surveyId,
        answerDetails,
        this.list
      );
    }
  },
  mounted() {
    this.placCon = this.question.options[0].content
    this.message = this.question.answer[0].answer ;
  }
};
</script>

<style scoped>
.media-content {
  border-radius: 6px;
  width: 100%;
  margin-bottom: 10px;
}
.full-width {
  width: 100%;
 /* height: 160px;*/
}
.question {
  overflow: hidden;
  padding-bottom: 10px;
  border-radius: 6px;
  background: transparent;
}
.question .question-margin {
  margin: 10px 6px;
  padding-bottom: 10px;
  font-size: 20px;
}
.options {
  max-height: 220px;
  overflow-y: auto;
  margin-top: 10px;
  padding-right: 3px;
  border: 1px solid#716C64;
}
.media {
  width: 100%;
  position: relative;
  height: 160px;
}
</style>
