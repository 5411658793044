<template>
  <div style="margin:0 20px">
    <div
      class="media-content"
      v-if="
        question != null && question.mediaType != '' && question.mediaUrl != ''
      "
    >
      <div v-if="question.mediaType == 1">
        <img class="full-width" :src="question.mediaUrl" />
      </div>
      <div v-if="question.mediaType == 2">
        <audio width="100%" type="video/mp4" controls>
          <source :src="question.mediaUrl"/>
        </audio>
      </div>
      <div v-if="question.mediaType == 3" class="media">
        <video width="100%" height="100%" type="video/mp4" controls disablepictureinpicture>
          <source :src="question.mediaUrl"/>
        </video>
      </div>
    </div>

    <div class="question">
      <div class="question-margin">
        <quessionnaire-tile :title="question"></quessionnaire-tile>
      </div>
      <div class="question-margin">
        <appSelect
          :options="question.options"
          @handle="handle"
          :content="question.content||'Please select the answer'"
          :type="type"
        ></appSelect>
        <!-- <van-dropdown-menu :overlay="false">
          <van-dropdown-item :title="answer" ref="item"  style="width:80%">
            <van-cell
              style="background-color: rgb(242, 242, 242);"
              v-for="item in question.options"
              :key="item.id"
              left
              :title="item.content"
              @click="select(item)"
            >
              <template #right-icon> </template>
            </van-cell>
          </van-dropdown-item>
        </van-dropdown-menu>-->
      </div>
    </div>
  </div>
</template>

<script>
import quessionnaireTile from "./questionnaireTitle";
import appSelect from "./appSelect";
export default {
  components: { quessionnaireTile, appSelect },
  props: { question: {}, list: {} },
  data() {
    return {
      answer: "",
      answerDetail: {
        answer: "",
        cascadeDetailCode: "",
        surveyOptionId: ""
      },
      type: "radio",
    };
  },
  methods: {
    select(option) {
      this.answer = option.content;
      this.$refs.item.toggle();
    },
    handle(option) {
      this.answer = option.id;
      this.question.content = option.content;
    },
    answerQuestion() {
      this.answerDetail.surveyOptionId = this.answer;
      this.answerDetail.questionId = this.question.questionId

      let answerDetails = [];
      answerDetails.push(this.answerDetail);
      this.list[this.question.sort - 1] = {
        ...this.question,
        answer: answerDetails
      };
      this.$emit(
        "answerQuestion",
        this.question.surveyId,
        this.answerDetail,
        this.list
      );
    }
  },
  mounted() {
    console.log("---this.questionthis.questionthis.question", this.question);
    this.answer = this.question.answer[0].surveyOptionId;
  }
};
</script>

<style scoped>
.media-content {
  border-radius: 6px;
  width: 100%;
  margin-bottom: 10px;
}
.full-width {
  width: 100%;
  /*height: 160px;*/
}
.question {
  overflow: hidden;
  padding-bottom: 10px;
  border-radius: 6px;
  background: transparent;
}
.question .question-margin {
  margin: 10px 6px;
  padding-bottom: 10px;
}
.van-cell {
  padding: 10px 16px;
}
.media {
  width: 100%;
  position: relative;
  height: 160px;
}
</style>
