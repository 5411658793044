<!--
 * @Author: Tom
 * @Date: 2022-06-30 14:18:07
 * @LastEditors: Eric
 * @Description:
-->
<template>
  <div class="bg-gray">
    <div class="select">
      <div class="result">
        <div :class="classNames">
          <van-field :value="searchText" placeholder="Please Seletc" @input="changeValue" @click.stop="handleSelect">
            <!-- <van-cell style="width:100%" :value="anSwerText"> -->
            <template #right-icon>
              <van-icon
                v-if="!isShow"
                name="arrow"
                class="search-icon"
                @click.stop="handleSelect"
              />
              <van-icon
                v-if="isShow"
                name="arrow-down"
                class="search-icon"
                @click.stop="handleSelect"
              />
            </template>
            <!-- </van-cell> -->
          </van-field>
        </div>
      </div>

      <div v-if="isShow" class="options">
        <van-cell-group style="background-color: transparent;">
          <van-cell
            style="border: 1px solid transparent;"
            :style="{backgroundColor: option.checked? '#E9E9EB': '' }"
            v-for="(option,i) in filterOptions"
            :key="option.cdsid"
            :value="option.name"
            @click.stop="select(option,i)"
          />
        </van-cell-group>
      </div>
      <div class="selected-content">
        <van-badge v-if="type === 2" color="transparent" class="selected"> Selected: {{answerList.length}}</van-badge>
        <van-badge v-if="!isShow" v-for="(item, i) in answerList" :key="i" color="transparent" class="sel-item">
          <div class="child">{{ item.name }}</div>
          <template #content>
            <van-icon name="clear" class="badge-icon" @click="onDelete(item.cdsid)" />
          </template>
        </van-badge>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions} from "vuex";
export default {
  props: { className: "", content: "", type: "" },
  watch: {
    className: {
      deep: true,
      immediate: true,
      handler(name) {
        this.classNames = name;
      },
    },
    // options: {
    //   handler(v) {
    //     if (v.length) {
    //       this.anSwerText = this.content || v[0].content;
    //       this.answer[0] = v[0].id;
    //       this.$emit("handle", { content: this.anSwerText, id: this.answer });
    //     }
    //   },
    //   immediate: true,
    //   deep: true,
    // },
    content: {
      handler(v) {
        this.answerList = v;
      },
      immediate: true,
    },
  },
  data() {
    return {
      classNames: this.classNames,
      isShow: false,
      anSwerText: "",
      answer: [],
      answerList: [],
      searchText: "",
      options: [],
      filterOptions: this.options
    };
  },
  methods: {
    ...mapActions({
      empSelct: "survey/empSelct",
    }),
    getOptions() {
     this.empSelct().then(res => {
        if (res.success === true) {
          this.options = res.data
          this.filterOptions = this.options
        }
      })
    },
    handleSelect() {
      if (!this.classNames) this.isShow = !this.isShow;
    },
    select(option, i) {
      this.filterOptions[i].checked = true;
      if (this.type === 2) {
        // this.anSwerText += option.content;
        let arr = this.answerList;
        arr.push(option);
        this.answerList = Array.from(new Set(arr));
        this.isShow = true;
      } else {
        this.filterOptions.forEach((e,j) => {
          if (j !== i) {
            e.checked = false;
          }
        })

        this.searchText = option.name;
        this.answerList = [option];
        this.isShow = false;
      }
      let arr = []
      this.answerList.forEach(e => {
        arr.push(e.name)
      });
      let answerStr = arr.join(";");
      this.$emit("handle", answerStr, this.answerList);
    },
    onDelete(id) {
      let arr = this.answerList
      const newArr = arr.filter(item => item.cdsid !== id)
      this.answerList = newArr
      this.searchText = ""
    },
    changeValue(e) { 
      this.isShow = true;
      this.searchText = e
      if (e === '') {
        this.filterOptions = this.options;
      }
      this.filterOptions = this.options.filter(item => item.name.toLowerCase().includes(e.toLowerCase()));
    }
  },
  mounted() {
    this.anSwerText = this.content;
    this.getOptions()
  },
};
</script>

<style scoped>
.bg-gray {
  /*background-color: rgb(242, 242, 242);
  padding: 20px;*/
  width: 100%;
}
.select {
  width: 100%;
}
.result {
  /* display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; */
}
.options {
  position: relative;
  z-index: 100;
  max-height: 220px;
  overflow-y: auto;
  margin-top: 10px;
  padding-right: 3px;
  border: 1px solid#716C64;
}
.search-icon {
  font-size: 16px;
  line-height: inherit;
}

.disabled-status .van-cell span,
.disabled-status .van-cell {
  color: rgba(153, 153, 153, 1);
}

.van-cell {
  padding: 10px 16px;
  background: transparent;
  border: 1px solid #716C64;
}
.selected-content{
  margin-top: 6px;
  max-height: 220px;
  overflow-y: auto;
}
.sel-item{
  margin: 10px 12px 0 0;
}
.selected{
  margin-right: 10px;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  background: transparent;
}
.child {
  min-height: 24px;
  line-height: 24px;
  padding: 0 6px;
  background: transparent;
  border: 1px solid #333;
  border-radius: 4px;
  text-align: center;
  font-size: 14px;
}
.badge-icon {
  cursor: pointer;
  display: block;
  font-size: 14px;
  color: #333;
  line-height: 10px;
}
</style>
<style>
.van-badge{
  border: 1px solid transparent !important;
}
</style>
