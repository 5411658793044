<!--
 * @Author: Eric
 * @Date: 2024-04-25 21:29:51
 * @LastEditors: Eric
 * @LastEditTime: 2024-05-06 15:25:53
 * @Description: file content
-->
<template>
  <div style="margin:20px">

    <div class="question">
      <div class="question-margin">
        <quessionnaire-tile :title="questions"></quessionnaire-tile>
      </div>
    </div>
  </div>
</template>

<script>
import quessionnaireTile from "./questionnaireTitle.vue";
export default {
  components: { quessionnaireTile },
  props: { question: {}, list: {} },
  data() {
    return {
      questions: {},
      answerDetail: {
        answer: "",
        cascadeDetailCode: "",
        surveyOptionId: ""
      }
    };
  },
  mounted() {
    this.questions = this.question;
    // this.questions.options.forEach(e => {
    //   e.value = 0;
    // });
    // console.log(this.questions);
  },
  watch:{
    // question:{
    //   handler(v){
    //     console.log(v)
    //   },
    //   immediate:true,
    //   deep:true
    // }
  },
  methods: {
    answerQuestion() {
      let answerDetails = [{
        answer: "111",
        surveyOptionId: -3,
        questionId: this.questions.questionId
      }];
      this.list[this.question.sort - 1] = {
        ...this.questions,
        answer: answerDetails
      };
      this.$emit(
        "answerQuestion",
        this.questions.surveyId,
        answerDetails,
        this.list
      );
    }
  }
};
</script>

<style scoped>
.media-content {
  border-radius: 6px;
  width: 100%;
  margin-bottom: 10px;
}
.full-width {
  width: 100%;
  /*height: 160px;*/
}
.question {
  overflow: hidden;
  padding-bottom: 10px;
  border-radius: 6px;
  background: transparent;
}
.question .question-margin {
  padding-bottom: 10px;
  font-size: 20px;
}
.media {
  width: 100%;
  position: relative;
  height: 160px;
}
</style>
