<template>
  <div style="margin:0 20px">
    <div
      class="media-content"
      v-if="
        question != null && question.mediaType != '' && question.mediaUrl != ''
      "
    >
      <div v-if="question.mediaType == 1">
        <img class="full-width" :src="question.mediaUrl" />
      </div>
      <div v-if="question.mediaType == 2">
        <audio width="100%" type="video/mp4" controls>
          <source :src="question.mediaUrl"/>
        </audio>
      </div>
      <div v-if="question.mediaType == 3" class="media media-video-cotent">
        <video width="100%" height="100%" type="video/mp4" controls disablepictureinpicture>
          <source :src="question.mediaUrl"/>
        </video>
      </div>
    </div>

    <div class="question">
      <div class="question-margin">
        <quessionnaire-title :title="question"></quessionnaire-title>
      </div>

      <div class="question-margin options">
        <van-radio-group v-model="answer" checked-color="#1B1A18;" shape="dot">
          <van-cell-group style="background-color: transparent">
            <van-cell
              :title="option.isOther?'Other':option.content"
              v-for="option in question.options"
              :key="option.id"
              style="background-color: transparent;"
              clickable
              @click="toggle()"
            >
              <template #icon>
                <van-radio :name="option.id" checked-color="#1B1A18" style="margin-right: 16px;"/>
              </template>
            </van-cell>
            <van-cell
              v-if="isCheckOther"
              style="background-color: transparent;"
            >
              <van-field v-model="value" :placeholder="otherMes ? otherMes : 'Please input your options'" />
            </van-cell>
          </van-cell-group>
        </van-radio-group>
      </div>
    </div>
  </div>
</template>

<script>
import quessionnaireTitle from "./questionnaireTitle.vue";

export default {
  components: { quessionnaireTitle },
  props: { question: {}, list: {}, isPageForEach: {} },
  data() {
    return {
      options: "",
      answer: "",
      answerDetail: {
        answer: "",
        cascadeDetailCode: "",
        surveyOptionId: ""
      },
      isCheckOther: false,
      value: "",
      otherMes: ''
    };
  },
  watch:{
    question:{
      handler (v) {
        // console.log(v);
        const is_page = v.answer && v.answer[0].surveyOptionId;
        this.answer = is_page;
        if(this.value){
          this.isCheckOther = true 
          this.answerDetail = v.answer[0]
        } else {
          this.isCheckOther = false
          this.answerDetail = {
            answer: "",
            cascadeDetailCode: "",
            surveyOptionId: ""
          }
        }
        let shortObj = v.options.filter(item=> item.id == is_page)
        if(shortObj.length && shortObj[0].isOther) {
          this.isCheckOther = true 
          this.answerDetail = v.answer[0]
          this.otherMes = shortObj[0].content
          this.value = v.answer && v.answer[0].answer
        }else{
          this.value = ''
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    toggle() {
      for (let i = 0; i < this.question.options.length; i++) {
        let option = this.question.options[i];
        if (option.id == this.answer) {
          if (option.isOther == 1) {
            this.isCheckOther = true;
            this.otherMes = option.content || ''
          } else {
            this.isCheckOther = false;
          }
        }
      }
    },
    answerQuestion() {
      // this.answer =
      //   this.isPageForEach.isPageForEach == 1
      //     ? this.question.answer[0].surveyOptionId
      //     : this.answer;
      this.answerDetail.surveyOptionId = this.answer;
      this.answerDetail.answer = this.value;
      this.answerDetail.questionId = this.question.questionId
      let answerDetails = [];
      answerDetails[0] = this.answerDetail;
      this.list[this.question.sort - 1] = {
        ...this.question,
        answer: answerDetails
      };
      this.$emit(
        "answerQuestion",
        this.question.surveyId,
        answerDetails,
        this.list
      );
    }
  },
  mounted() {
    
    // console.log("this.isPageForEach", this.isPageForEach);
  }
};
</script>

<style lang="less" scoped>
.media-content {
  border-radius: 6px;
  width: 100%;
  margin-bottom: 10px;
}
.full-width {
  width: 100%;
  /*height: 160px;*/
}
.question {
  overflow: hidden;
  padding-bottom: 10px;
  border-radius: 6px;
  background: transparent;
}
.question .question-margin {
  margin: 10px 6px;
  padding-bottom: 10px;
}
.options{
  max-height: 220px;
  overflow-y: auto;
  margin-top: 10px;
  padding-right: 3px;
}
.van-cell {
  padding: 10px 16px;
}
.media {
  width: 100%;
  position: relative;
  height: 160px;
}
.full-media-width {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
}
.full-audio-width {
  width: 100%;
}
.media {
  width: 100%;
  position: relative;
  height: 160px;
}
</style>
