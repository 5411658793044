<!--
 * @Author: Tom
 * @Date: 2022-06-30 14:18:07
 * @LastEditors: Eric
 * @Description:
-->
<template>
  <div style="margin:0 20px">
    <div
      class="media-content"
      v-if="
        question != null && question.mediaType != '' && question.mediaUrl != ''
      "
    >
      <div v-if="question.mediaType == 1">
        <img class="full-width" :src="question.mediaUrl" />
      </div>
      <div v-if="question.mediaType == 2">
        <audio width="100%" type="video/mp4" controls>
          <source :src="question.mediaUrl"/>
        </audio>
      </div>
      <div v-if="question.mediaType == 3" class="media">
        <!-- <audio class="full-width"></audio> -->
        <video width="100%" height="100%" type="video/mp4" controls disablepictureinpicture>
          <source :src="question.mediaUrl"/>
        </video>
      </div>
    </div>
    <div class="question">

      <div class="question-margin">
        <quessionnaire-tile :title="question"></quessionnaire-tile>
      </div>
    

      <div class="question-margin options">
        <van-radio-group v-model="answer">
          <van-cell-group>
            <van-cell
              v-for="option in question.options"
              :key="option.id"
              style="background-color: rgb(242, 242, 242); margin-top:20px"
              clickable
            >
              <img
                :class="option.id !== answer && answer !== -1 ? 'opacity-5' : '' "
                style="width:200px"
                :src="option.content"
              />
              <template #right-icon>
                <van-radio :name="option.id" checked-color="#BDAC9B"/>
              </template>
            </van-cell>
          </van-cell-group>
        </van-radio-group>
      </div>
    </div>
  </div>
</template>

<script>
import quessionnaireTile from "./questionnaireTitle.vue";
// import VideoPlayer from "@/components/VideoPlayer.vue";
export default {
  components: { quessionnaireTile },
  props: { question: {}, list: {}, isPageForEach: {} },
  data() {
    return {
      answer: "",
      answerDetail: {
        answer: "",
        cascadeDetailCode: "",
        surveyOptionId: ""
      }
    };
  },
  methods: {
    answerQuestion(option) {
      // this.answer =
      //   this.isPageForEach.isPageForEach == 1
      //     ? this.question.answer[0].surveyOptionId
      //     : this.answer;
      this.answerDetail.surveyOptionId = this.answer;
      this.answerDetail.questionId = this.question.questionId
      let answerDetails = [];
      answerDetails.push(this.answerDetail);
      this.list[this.question.sort - 1] = {
        ...this.question,
        answer: answerDetails
      };
      this.$emit(
        "answerQuestion",
        this.question.surveyId,
        answerDetails,
        this.list
      );
    }
  },
  watch:{
    answer:{
      handler(v){
        console.log(v,v=== -1)
      },
      immediate:true,
      deep:true
    }
  },
  mounted() {
    // console.log("this.question", this.question);
    this.answer = this.question.answer[0].surveyOptionId;
  }
};
</script>

<style scoped>
.media-content {
  border-radius: 6px;
  width: 100%;
  margin-bottom: 10px;
}
.full-width {
  width: 100%;
  /*height: 160px;*/
}
.question {
  overflow: hidden;
  padding-bottom: 10px;
  border-radius: 6px;
  background: transparent;
}
.question .question-margin {
  margin: 10px 6px;
  padding-bottom: 10px;
}
.options{
  max-height: 220px;
  overflow-y: auto;
  margin-top: 10px;
  padding-right: 3px;
}
.van-cell {
  padding: 10px 16px;
}
.media {
  width: 100%;
  position: relative;
  height: 160px;
}
</style>
