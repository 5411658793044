<template>
  <div style="margin: 0 20px">
    <div
      class="media-content"
      v-if="
        quessionCascader != null && quessionCascader.mediaType != '' && quessionCascader.mediaUrl != ''
      "
    >
      <div v-if="quessionCascader.mediaType == 1">
        <img class="full-width" :src="quessionCascader.mediaUrl" />
      </div>
      <div v-if="quessionCascader.mediaType == 2">
        <audio width="100%" type="video/mp4" controls>
          <source :src="quessionCascader.mediaUrl"/>
        </audio>
      </div>
      <div v-if="quessionCascader.mediaType == 3" class="media">
        <video width="100%" height="100%" type="video/mp4" controls disablepictureinpicture>
          <source :src="quessionCascader.mediaUrl"/>
        </video>
      </div>
    </div>

    <div class="question">
      <div class="question-margin">
        <quessionnaire-tile :title="quessionCascader"></quessionnaire-tile>
      </div>
      <div class="question-margin">
        <app-select
          :options="options1"
          @handle="handle1"
          :type="type"
          :content="quessionCascader.province||'Please select an option'"
        ></app-select>
        <app-select
          :className="secondLevel ? 'disabled-status' : ''"
          :options="options2"
          @handle="handle2"
          :content="quessionCascader.city||'Please select an option'"
          :type="type"
        ></app-select>
        <app-select
          :className="threeLevel ? 'disabled-status' : ''"
          :options="options3"
          @handle="handle3"
          :content="quessionCascader.area||'Please select an option'"
          :type="type"
        ></app-select>
      </div>
    </div>
  </div>
</template>

<script>
import quessionnaireTile from "./questionnaireTitle";
import appSelect from "./appSelect";
import { mapActions} from "vuex";

export default {
  components: { quessionnaireTile, appSelect },
  props: { question: {}, list: {} },
  data() {
    return {
      secondLevel: true,
      threeLevel: true,
      quession: {
        answer1: "123123",
        answerText1: "选项1",
        answer2: "123123",
        answerText2: "选项1",
        answer3: "123123",
        answerText3: "选项1"
      },
      answerDetail: {
        answer: "",
        cascadeDetailCode: "",
        surveyOptionId: ""
      },
      answer: "",
      options1: [],
      options2: [],
      options3: [],
      optionsSource1: [],
      optionsSource2: [],
      optionsSource3: [],
      type: "cascader",
      cascader: {
        province: "请选择选项",
        city: "请选择选项",
        area: "请选择选项"
      },
      quessionCascader:{}
    };
  },
  created() {
    // this.getCascaser();
  },
  methods: {
    ...mapActions({
      cascadSelct: "survey/cascadSelct",
    }),
    select(index, option) {
      this.quession["answer" + index] = option.value;
      this.quession["answerText" + index] = option.text;
      this.$refs["item" + index].toggle();
    },

    getCascaser() {
      this.cascadSelct(this.quessionCascader.surveyCascadeCode)
        .then(res => {
          console.log("-------", res);
          this.optionsSource1 = res.data.cascadeDetails;

          this.options1 = this.handleSource(this.optionsSource1);
          if(!this.quessionCascader.province){
            this.quessionCascader.options1 = this.handleSource(this.optionsSource1);
            this.quessionCascader.province = this.options1[0].content
            this.answer = this.optionsSource1[0].children[0].children[0].code;
          }else{
            this.initSave()
          }
        });
    },
    handleSource(source) {
      let array = [];
      for (const item of source) {
        let a = {};
        a["id"] = item.code;
        a["content"] = item.name;
        array.push(a);
      }
      return array;
    },

    handle1(option) {
      this.secondLevel = false;
      this.quessionCascader.province = option.content;
      for (const item of this.optionsSource1) {
        if (item.name == option.content) {
          
          this.optionsSource2 = item.children;
          this.options2 = this.handleSource(this.optionsSource2);
          !this.quessionCascader.city && (this.quessionCascader.city = this.options2[0].content)
          this.options3 = this.handleSource(this.optionsSource2[0].children)
          !this.quessionCascader.area && (this.quessionCascader.area = this.options3[0].content)
          this.quessionCascader.options2 = this.handleSource(this.optionsSource2);
          this.answer = this.options3[0].id
          if(item.name === option.content && item.code === option.id){
            this.quessionCascader.city = this.options2[0].content
            this.quessionCascader.area = this.options3[0].content
          }
        }
      }
      // for(let itemd of this.options1){
      //   if(itemd.content === option.content){
      //     if(itemd.id === option.id){
      //       for (const item of this.optionsSource1) {
      //         if (item.code == option.id) {
      //           this.optionsSource2 = item.children;
      //           this.options2 = this.handleSource(this.optionsSource2);
      //           this.quessionCascader.city = this.options2[0].content
      //           this.options3 = this.handleSource(this.optionsSource2[0].children)
      //           this.quessionCascader.area = this.options3[0].content
      //           this.quessionCascader.options2 = this.handleSource(this.optionsSource2);
      //           this.answer = this.options3[0].id
      //         }
      //       }  
      //     }else{
      //       for (const item of this.optionsSource1) {
      //         if (item.name == option.content) {
      //           this.optionsSource2 = item.children;
      //           this.options2 = this.handleSource(this.optionsSource2);
      //           this.quessionCascader.city = this.options2[0].content
      //           this.options3 = this.handleSource(this.optionsSource2[0].children)
      //           this.quessionCascader.area = this.options3[0].content
      //           this.quessionCascader.options2 = this.handleSource(this.optionsSource2);
      //           this.answer = this.options3[0].id
      //         }
      //       }
      //     }
      //   }
      // }
      
    },
    handle2(option) {
      this.threeLevel = false;
      this.quessionCascader.city = option.content;
      for (const item of this.optionsSource2) {
        if (item.name == option.content) {
          this.optionsSource3 = item.children;
          this.options3 = this.handleSource(this.optionsSource3)
          !this.quessionCascader.area && (this.quessionCascader.area = this.options3[0].content)
          this.quessionCascader.options3 = this.handleSource(this.optionsSource3)
          this.answer = this.options3[0].id
          if(option.id === item.code && option.content === item.name){
            this.quessionCascader.area = this.options3[0].content
          }
        }
      }
    },
    handle3(option) {
      this.answer = option.id;
      this.quessionCascader.area = option.content;
    },
    answerQuestion() {
      
      let answers = [];
      let answerSave = {
        provinceAns : this.quessionCascader.province,
        cityAns : this.quessionCascader.city,
        areaAns: this.quessionCascader.area
      }
      this.answerDetail.surveyOptionId = this.quessionCascader.options[0].id;
      this.answerDetail.cascadeDetailCode = this.answer;
      answers.push(this.answerDetail);

      this.list[this.quessionCascader.sort - 1] = {
        ...this.question,
        answer: answers,
        answerSave:answerSave
      };
      this.quessionCascader.cascader = this.cascader;
      this.$emit("answerQuestion", this.quessionCascader.surveyId, answers, this.list);
    },
    initSave(){
      for (let item of this.optionsSource1) {
        if(item.name === this.quessionCascader.province){
          this.optionsSource2 = JSON.parse(JSON.stringify(item.children));
          this.options2 = this.handleSource(this.optionsSource2);
          for(const itemd of this.optionsSource2){
            if(itemd.name = this.quessionCascader.city){
              this.optionsSource3 =itemd.children
              this.options3 = this.handleSource(this.optionsSource3)
            }
          }
        }
      }
    }
  },
  watch:{
    question:{
      async handler(v){
        this.quessionCascader = JSON.parse(JSON.stringify(v))
        if(v.answerSave && v.answerSave.provinceAns){
          this.quessionCascader.province = v.answerSave.provinceAns
          this.quessionCascader.city = v.answerSave.cityAns
          this.quessionCascader.area = v.answerSave.areaAns
        }
      },
      immediate:true,
      deep:true
    }
  },
  mounted() {
   
  }
};
</script>

<style scoped>
.media-content {
  border-radius: 6px;
  width: 100%;
  margin-bottom: 10px;
}
.full-width {
  width: 100%;
  /*height: 160px;*/
}
.question {
  overflow: hidden;
  padding-bottom: 10px;
  border-radius: 6px;
  background: transparent;
}
.question .question-margin {
  margin: 10px 6px;
  padding-bottom: 10px;
}
.media {
  width: 100%;
  position: relative;
  height: 160px;
}
</style>
