<!--
 * @Author: Eric
 * @Date: 2024-04-19 16:08:20
 * @LastEditors: Eric
 * @LastEditTime: 2024-05-05 20:34:09
 * @Description: file content
-->
<template>
  <div class="grid-content">
    <!-- <div class="title-font">{{ title.sort }}.</div> -->
    <!-- <div class="title-font" v-if="title.type < 5">
      <van-tag
        style="width: 60px;"
        color="#DDBFA1"
        text-color="rgba(0, 0, 0, 0.8)"
        size="large"
        round
        >{{ typeMap[title.type] }}</van-tag
      >
    </div> -->
    <div class="title-font title-description">{{ title.name }}</div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      sort: "",
      type: "",
      name: ""
    }
  },
  data() {
    return {
      typeMap: {
        1: "单选题",
        2: "多选题",
        3: "单选题",
        4: "多选题",
        5: "下拉题",
        6: "级联题",
        7: "填空题",
        8: "评价题"
      }
    };
  }
};
</script>

<style scoped>
.title-font {
  font-size: 18px;
  font-weight: 600;
  box-sizing: border-box;
  align-self: center;
}
.grid-content {
  display: flex;
  grid-template-columns: 20px auto auto;
  grid-column-gap: 10px;
}
.title-description {
  justify-self: start;
 
}
</style>
