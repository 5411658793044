<template>
  <div class="bg-gray">
    <div class="select">
      <div class="result" @click.stop="handleSelect">
        <div :class="classNames">
          <!-- <van-field :value="anSwerText" placeholder="请选择" readonly /> -->
          <van-cell style="width:100%" :value="anSwerText">
            <template #right-icon>
              <van-icon v-if="!isShow" name="arrow" class="search-icon" />
              <van-icon v-if="isShow" name="arrow-down" class="search-icon" />
            </template>
          </van-cell>
        </div>
      </div>

      <div v-if="isShow" class="options">
        <van-cell-group inset style="background-color: transparent;">
          <van-cell
            style="border: 1px solid transparent;"
            @click.stop="select(option)"
            v-for="option in options"
            :key="option.id"
            :value="option.content"
          />
        </van-cell-group>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: { options: {}, className: "", content: "", type: "" },
  watch: {
    className: {
      deep: true,
      immediate: true,
      handler(name) {
        this.classNames = name;
      }
    },
    options:{
      handler(v){
        if(v.length){
          this.anSwerText =  this.content || v[0].content
          this.answer = v[0].id
          this.$emit('handle',{content: this.anSwerText,id:this.answer})
        }
      },
      immediate: true,
      deep: true
    },
    content:{
      handler(v){
        this.anSwerText = v
      },
      immediate:true
    }
  },
  data() {
    return {
      classNames: this.classNames,
      isShow: false,
      anSwerText: "请选择答案选项",
      answer: ""
    };
  },
  methods: {
    handleSelect() {
      if (!this.classNames) this.isShow = !this.isShow;
    },
    select(option) {
      this.anSwerText = option.content;
      this.answer = option.id;
      this.isShow = false;
      this.$emit("handle", option);
    }
  },
  mounted() {
    this.anSwerText = this.content;
  }
};
</script>

<style scoped>
.bg-gray {
  /*background-color: rgb(242, 242, 242);
  padding: 20px;*/
  width: 100%;
}
.select {
  width: 100%;
}
.result {
  /* display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; */
}
.options {
  z-index: 100;max-height: 220px;
  overflow-y: auto;
  margin-top: 10px;
  padding-right: 3px;
  border: 1px solid#716C64;
}
.search-icon {
  font-size: 16px;
  line-height: inherit;
}

.disabled-status .van-cell span,
.disabled-status .van-cell {
  color: rgba(153, 153, 153, 1);
}

.van-cell {
  padding: 10px 16px;
  background: transparent;
  border: 1px solid #716C64;
}
</style>
