<template>
  <div style="margin:0 20px">
    <div
      class="media-content"
      v-if="
        questions != null &&
          questions.mediaType != '' &&
          questions.mediaUrl != ''
      "
    >
      <div v-if="questions.mediaType == 1">
        <img class="full-width" :src="questions.mediaUrl" />
      </div>
      <div v-if="questions.mediaType == 2">
        <audio width="100%" type="video/mp4" controls>
          <source :src="question.mediaUrl"/>
        </audio>
      </div>
      <div v-if="questions.mediaType == 3" class="media">
        <video width="100%" height="100%" type="video/mp4" controls disablepictureinpicture>
          <source :src="question.mediaUrl"/>
        </video>
      </div>
    </div>

    <div class="question">
      <div class="question-margin">
        <quessionnaire-tile :title="questions"></quessionnaire-tile>
      </div>
      <div class="question-margin">
        <div
          v-for="item in questions.options"
          :key="item.id"
          style="margin-top:25px"
        >
          <!-- <p>{{ item.content }}</p> -->
          <van-rate
            style="margin-top:15px"
            v-model="item.answer"
            :size="40"
            :gutter="30"
            color="#FECC52"
            void-icon="star"
            void-color="#C5C5C6"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import quessionnaireTile from "./questionnaireTitle.vue";
export default {
  components: { quessionnaireTile },
  props: { question: {}, list: {} },
  data() {
    return {
      questions: {},
      answerDetail: {
        answer: "",
        cascadeDetailCode: "",
        surveyOptionId: ""
      }
    };
  },
  mounted() {
    this.questions = this.question;
    // this.questions.options.forEach(e => {
    //   e.value = 0;
    // });
    // console.log(this.questions);
  },
  watch:{
    // question:{
    //   handler(v){
    //     console.log(v)
    //   },
    //   immediate:true,
    //   deep:true
    // }
  },
  methods: {
    answerQuestion() {
      let answerDetails = [];
      for (let i = 0; i < this.question.options.length; i++) {
        let item = this.question.options[i];
        let a = { 
          answer: item.answer,
          surveyOptionId: item.answer ? item.id : -1,
          questionId: this.question.questionId
        };
        answerDetails.push(a);
      }
      this.list[this.question.sort - 1] = {
        ...this.questions,
        answer: answerDetails
      };
      this.$emit(
        "answerQuestion",
        this.questions.surveyId,
        answerDetails,
        this.list
      );
    }
  }
};
</script>

<style scoped>
.media-content {
  border-radius: 6px;
  width: 100%;
  margin-bottom: 10px;
}
.full-width {
  width: 100%;
  /*height: 160px;*/
}
.question {
  overflow: hidden;
  padding-bottom: 10px;
  border-radius: 6px;
  background: transparent;
}
.question .question-margin {
  margin: 10px 6px;
  padding-bottom: 10px;
  font-size: 20px;
}
.media {
  width: 100%;
  position: relative;
  height: 160px;
}
</style>
