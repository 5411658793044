<template>
  <div :class="quessionnaire.coverImg ?'background':'background-img'" :style="{backgroundImage: 'url('+quessionnaire.coverImg+')'}">
   <!-- <div class="background"> -->
    <div class="caontainer">
      <div class="header">
        <img class="logo" src="../../../assets/quessionnaire/logo.png" />
            <!-- <h2>{{ quessionnaire.title }}</h2> -->
      </div>
      <div class="content">
        <!-- 进入页面 -->
        <!-- <div class="content-header">
          <img style="height:40px" src="../assets/image/quessionnaire/pony1.png" />
        </div> -->
        <div v-if="isHome" class="home" style="">
          <!-- <div class="content-title" >
            <h2 style="font-size:28px">{{ quessionnaire.title }}</h2>
          </div> -->
          <!-- <div class="thanksForParticipation" v-if="thanksForParticipation">{{quessionnaire.title}}</div> -->

          <div class="content-title" v-if="!thanksForParticipation">
            <h2 >{{quessionnaire.isFinished=== true?'':quessionnaire.title }}</h2>
          </div>
          <div class="content-greetWord" v-if="!thanksForParticipation">
            <p>{{quessionnaire.isFinished=== true?'':quessionnaire.greetWord}}</p>
          </div>
          <div class="content-greetWord conclusion" v-if="thanksForParticipation">
            <p>{{quessionnaire.conclusion}}</p>
          </div>
          <img v-if="isHome&&quessionnaire.homePageUrl&&!thanksForParticipation" class="home-img" :src="quessionnaire.homePageUrl" />
          <img v-if="isHome&&quessionnaire.endPageUrl&&thanksForParticipation" class="home-img" :src="quessionnaire.endPageUrl" />

        </div>

        <div class="active-ts" v-if="quessionnaire.isFinished === true">The survey has been completed, thanks for your participation</div>

        <!-- 答题内容 -->
        <div v-if="!isHome">
          <!-- <div class="content-header">
            <img
              style="height:20px"
              src="@/assets/image/quessionnaire/pony1.png"
            />
          </div>-->
          <!-- 一页所有题目 -->
          <div v-if="!isPageForEach">
            <div v-for="(question, index) of list" :key="index">
              <div v-if="question.type == 1">
                <questionnaireRadio
                  :ref="'question' + index"
                  :key="key"
                  :question="question"
                  :list="list"
                  :isPageForEach="isPage"
                  @answerQuestion="answerQuestion"
                ></questionnaireRadio>
              </div>
              <div v-if="question.type == 2">
                <questionnaireCheckbox
                  :ref="'question' + index"
                  :key="key"
                  :list="list"
                  :question="question"
                  :isPageForEach="isPage"
                  @answerQuestion="answerQuestion"
                ></questionnaireCheckbox>
              </div>

              <div v-if="question.type == 3">
                <quessionnaire-img-radio
                  :ref="'question' + index"
                  :key="key"
                  :list="list"
                  :question="question"
                  :isPageForEach="isPage"
                  @answerQuestion="answerQuestion"
                ></quessionnaire-img-radio>
              </div>
              <div v-if="question.type == 4">
                <quessionnaire-img-checkbox
                  :ref="'question' + index"
                  :key="key"
                  :list="list"
                  :question="question"
                  :isPageForEach="isPage"
                  @answerQuestion="answerQuestion"
                ></quessionnaire-img-checkbox>
              </div>
              <div v-if="question.type == 5">
                <quessionnaireSelect
                  :ref="'question' + index"
                  :key="key"
                  :list="list"
                  :question="question"
                  :isPageForEach="isPage"
                  @answerQuestion="answerQuestion"
                ></quessionnaireSelect>
              </div>
              <div v-if="question.type == 6">
                <quessionnaire-cascader
                  :ref="'question' + index"
                  :key="key"
                  :list="list"
                  :question="question"
                  :isPageForEach="isPage"
                  @answerQuestion="answerQuestion"
                ></quessionnaire-cascader>
              </div>
              <div v-if="question.type == 7">
                <quessionnaireTextarea
                  :ref="'question' + index"
                  :key="key"
                  :list="list"
                  :question="question"
                  :isPageForEach="isPage"
                  @answerQuestion="answerQuestion"
                ></quessionnaireTextarea>
              </div>
              <div v-if="question.type == 8">
                <quessionnaireRate
                  :ref="'question' + index"
                  :key="key"
                  :list="list"
                  :question="question"
                  :isPageForEach="isPage"
                  @answerQuestion="answerQuestion"
                ></quessionnaireRate>
              </div>
              <div v-if="question.type == 9">
                <questionnaireLabel
                  :ref="'question' + index"
                  :key="key"
                  :list="list"
                  :question="question"
                  :isPageForEach="isPage"
                  @answerQuestion="answerQuestion"
                ></questionnaireLabel>
              </div>
              <div v-if="question.type == 11">
                <questionnaireEmpselect
                  :ref="'question' + index"
                  :key="key"
                  :list="list"
                  :question="question"
                  :isPageForEach="isPage"
                  @answerQuestion="answerQuestion"
                ></questionnaireEmpselect>
              </div>
            </div>
          </div>
          <!-- 一页一题 -->
          <div v-if="question != null && isPageForEach">
            <div class="progress">
              <h4>Let’s Start...</h4>
              <!-- 进度 -->
              <van-progress :percentage="percentage" :show-pivot="false" color="#000" track-color="#E9E9EB" />
              <p><span>{{parseInt(this.indexD+1)}}</span>/{{list.length}}</p>
            </div>
            
            <div v-if="question.type == 1">
              <questionnaireRadio
                :ref="question.sort"
                :key="key"
                :list="list"
                :question="question"
                :isPageForEach="isPage"
                @answerQuestion="answerQuestion"
              ></questionnaireRadio>
            </div>
            <div v-if="question.type == 2">
              <questionnaireCheckbox
                :ref="question.sort"
                :key="key"
                :list="list"
                :question="question"
                :isPageForEach="isPage"
                @answerQuestion="answerQuestion"
              ></questionnaireCheckbox>
            </div>

            <div v-if="question.type == 3">
              <quessionnaire-img-radio
                :ref="question.sort"
                :key="key"
                :list="list"
                :question="question"
                :isPageForEach="isPage"
                @answerQuestion="answerQuestion"
              ></quessionnaire-img-radio>
            </div>
            <div v-if="question.type == 4">
              <quessionnaire-img-checkbox
                :ref="question.sort"
                :key="key"
                :list="list"
                :question="question"
                :isPageForEach="isPage"
                @answerQuestion="answerQuestion"
              ></quessionnaire-img-checkbox>
            </div>
            <div v-if="question.type == 5">
              <quessionnaireSelect
                :ref="question.sort"
                :key="key"
                :list="list"
                :question="question"
                :isPageForEach="isPage"
                @answerQuestion="answerQuestion"
              ></quessionnaireSelect>
            </div>
            <div v-if="question.type == 6">
              <quessionnaire-cascader
                :ref="question.sort"
                :key="key"
                :list="list"
                :question="question"
                :isPageForEach="isPage"
                @answerQuestion="answerQuestion"
              ></quessionnaire-cascader>
            </div>
            <div v-if="question.type == 7">
              <quessionnaireTextarea
                :ref="question.sort"
                :key="key"
                :list="list"
                :question="question"
                :isPageForEach="isPage"
                @answerQuestion="answerQuestion"
              ></quessionnaireTextarea>
            </div>
            <div v-if="question.type == 8">
              <quessionnaireRate
                :ref="question.sort"
                :key="key"
                :list="list"
                :question="question"
                :isPageForEach="isPage"
                @answerQuestion="answerQuestion"
              ></quessionnaireRate>
            </div>
            <div v-if="question.type == 9">
                <questionnaireLabel
                  :ref="question.sort"
                  :key="key"
                  :list="list"
                  :question="question"
                  :isPageForEach="isPage"
                  @answerQuestion="answerQuestion"
                ></questionnaireLabel>
            </div>
            <div v-if="question.type == 11">
                <questionnaireEmpselect
                  :ref="question.sort"
                  :key="key"
                  :list="list"
                  :question="question"
                  :isPageForEach="isPage"
                  @answerQuestion="answerQuestion"
                ></questionnaireEmpselect>
            </div>
          </div>
        </div>
        <div class="footer">
        <div
          v-if="quessionnaire.isFinished === true"
          
        ></div>
        <div v-else class="footer-inner">
          <div
            v-if="isPrevious && isPageForEach&&!isEnd&&!isHome"
            @click="previous"
            class="active-btn helf"
            style="background: #fff;color: #000;"
            :style="{marginRight: isNext ? '30px' : ''}"
          >PREVIOUS</div>
          <div v-if="isNext && isPageForEach&&!isHome" @click="next" class="active-btn helf">NEXT</div>

          <!-- <div
            v-if="
              indexD == list.length - 1 && isPageForEach == 1 && !isHome
            "
            @click="answerQuessionnaire"
            class="active-btn helf"
          >SUBMIT</div> -->
          <!-- <template style="color:white"></template> -->

          <!-- <div
            v-if="isPageForEach == 0 && !isHome"
            @click="answerQuessionnaire"
            class="active-btn"
          >SUBMIT</div> -->

          <div v-if="isHome && !isEnd" @click="into" class="active-btn anter">ENTER</div>
          
        </div>
      </div>
      </div>
      
      </div>
      
    </div>
  </div>
</template>

<script>
import questionnaireRadio from "../../../components/quesstionnaire/questionnaireRadio.vue";
import questionnaireCheckbox from "../../../components/quesstionnaire/quesstionnaireCheckbox.vue";
import quessionnaireImgRadio from "../../../components/quesstionnaire/quessionnaireImgRadio";
import quessionnaireImgCheckbox from "../../../components/quesstionnaire/quessionnaireImgCheckbox";
import quessionnaireSelect from "../../../components/quesstionnaire/quessionnaireSelect";
import questionnaireEmpselect from "../../../components/quesstionnaire/questionnaireEmpselect";
import quessionnaireCascader from "../../../components/quesstionnaire/quessionnaireCascader";
import quessionnaireRate from "../../../components/quesstionnaire/quessionnaireRate";
import quessionnaireTextarea from "../../../components/quesstionnaire/quessionnaireTextarea";
import questionnaireLabel from "../../../components/quesstionnaire/questionnaireLabel";
// import VideoPlayer from "../../components/VideoPlayer";
// import Video from "../car/video.vue";
import QuessionnaireImgRadio from "../../../components/quesstionnaire/quessionnaireImgRadio.vue";
import QuessionnaireCascader from "../../../components/quesstionnaire/quessionnaireCascader.vue";
import { set } from "store";
export default {
  props: { datas: {} },
  components: {
    questionnaireRadio,
    questionnaireCheckbox,
    quessionnaireImgRadio,
    quessionnaireImgCheckbox,
    quessionnaireSelect,
    quessionnaireCascader,
    quessionnaireRate,
    quessionnaireTextarea,
    questionnaireLabel,
    QuessionnaireImgRadio,
    QuessionnaireCascader,
    questionnaireEmpselect
  },
  data() {
    return {
      answers: [],
      options: {
        sources: [
          {
            src: "https://www.bilibili.com/video/BV1M44y1b7CE?t=0.0"
          }
        ]
      },
      indexD: 0,
      question: {},
      list: [],
      quessionnaire: {},
      isNext: true,
      isPrevious: true,
      key: 0,
      answer: {
        takeTime: 0,
        answerDetails: []
      },
      answerDetail: {
        answer: "",
        cascadeDetailCode: "",
        surveyOptionId: ""
      },
      startTime: 0,
      endTime: 0,
      surveyList: [],
      isPageForEach: 0,
      isHome: true,
      isFinished: false,
      isEnd: false,
      isPage: {},
      thanksForParticipation: false,
      channel: ''
    };
  },
  computed: {
    percentage() {
      // return 80;
      return (parseInt(this.indexD+1) / this.list.length) * 100
    }
  },
  watch: {
    indexD: {
      handler(newVal, oldVal) {
        console.log(newVal, oldVal);
        if (newVal < 0) {
          this.isPrevious = false;
        } else {
          this.isPrevious = true;
        }
        if (newVal >= this.list.length - 1) {
          // this.isPrevious = false;
          this.isNext = false;
        } else {
          this.isNext = true;
        }
      },
      immediate: true,
    },
    list: {
      deep: true,
      immediate: true,
      handler(value) {
        console.log(value);
      }
    },
    datas: {
      deep: true,
      immediate: true,
      handler(value) {
        this.getQuesDetail();
      }
    },
  },
  mounted() {
      this.isPrevious = false;
   
    this.getQuesDetail();

    if (this.list.length == 0) {
      this.isNext = false;
        }
    
  },
  methods: {
    next() {
      if (this.indexD <= this.list.length - 1) {
        console.log(this.list[this.indexD]);
        this.indexD++;
        this.$nextTick(()=>{
          this.$refs[this.question.sort].answerQuestion();
        })
      }
    },
    previous() {
      
      if (this.indexD === 0) {
        this.isHome = true;
      } else if (this.indexD >= 1) {
        this.indexD--;
        this.$nextTick(()=>{
          this.$refs[this.question.sort].answerQuestion();
        })
        // this.answers.pop();
        // console.log("this.answers", this.answers);
      }
    },
    getQuestion() {
      if (this.isPageForEach == 1) {
        let isAnswer =
          this.list[this.indexD] && this.list[this.indexD].answer
            ? this.list[this.indexD].answer
            : [{ surveyOptionId: -1 }];
        this.question = {}
        setTimeout(()=>{
          this.question = {
            ...this.list[this.indexD],
            sort: this.indexD + 1,
            answer: isAnswer
          }
        })
      }
    },
    getQuesDetail() {
      setTimeout(()=>{
        this.quessionnaire = this.datas;
        this.list = this.quessionnaire.questions;
        this.isPageForEach = this.quessionnaire.isPageForEach;
        this.isPage = { isPageForEach: this.quessionnaire.isPageForEach };
        this.isFinished = this.quessionnaire.isAnswer;
        if (this.isPageForEach == 1) {
          this.question = {
            ...this.list[0],
            sort: 1,
            answer: [{ surveyOptionId: -1 }]
          };
        } else {
          this.list.filter(f => {
            f.answer = [{ surveyOptionId: -1 }];
          });
        } 
        this.key++;
        this.getQuestion();
      },500)  
    },
    answerQuestion(questionid, answerDetails, newList) {
      this.list = newList
      if (answerDetails && answerDetails.length > 0) {
        answerDetails.filter(f => {
          this.answers.push({
            ...f
          });
        });
      }
      this.getQuestion();
    },
    //进入答题
    into() {
      this.startTime = new Date().getTime();
      this.isHome = false;
      if (this.list.length>1) {
        this.isNext = true;
      } else {
        this.isNext = false;
      }
    }
  }
};
</script>

<style lang="less" scoped>
@rem: 375/16rem;
.background-img {
  background: url("../../../assets/quessionnaire/car.png");
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  background-size: cover;
}
.background {
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  background-size: cover;
}

.caontainer {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 60px 1fr 10px;
  grid-template-columns: 100%;
}

.header {
  display: flex;
  align-items: center;
  padding-left: 25px;
  h2{
    margin-left: 20px;
    font-size:20px;
  }
  .logo{
    height:36px;
    position: relative;
    top: 8px;
  }
}
.content {
  overflow: auto;
  min-height:400px;
  width: 100%;
  position: relative;
  background-color: transparent;
}
.content .content-header {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 50px;
  align-items: center;
  margin-left: 20px;
}
.footer {
  position: fixed;
  bottom: 52px;
  align-self: start;
  justify-self: center;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  padding: 30px 0;
}
.footer-inner {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}
.home {
  display: grid;
  /*grid-template-rows: 180px  1fr;
  grid-template-columns: 90%;*/
  place-items: center;
  /*place-content: center;*/
  width: 100%;
}
.home-img{
  display: block;
  width: 100%;
}
.content-title{
  width: 80%;
  margin-top: 130px;
  h2{
    font-size: 26px;
    white-space: pre-wrap;
  }
}

.content-greetWord {
  width: 80%;
  margin-top: 26px;
  p{
    font-size: 18px;  
    line-height: 1.3;
    white-space: pre-wrap;
  }
}
.conclusion{
  margin-top: 30vh;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
}
.progress{
  margin: 24px 26px 4px;
  h4{
    margin-bottom: 20px;
    font-size: 18px;
  }
  p{
    margin-top: 20px;
    text-align: right;
    font-size: 14px;
    color: #929292;
    span{
      font-size: 18px;
      color: #000;
    }
  }
}
.active-ts {
  color: #000;
  font-size: 14px;
  text-align: center;
}
.active-btn {
  width: 327px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  border-radius: 2px;
  color: #fff;
  background: #302E2B;
  font-size: 16px;
  font-weight: 500;
}
.helf {
  width: 155px;
  /*margin-left: 27px;*/
}
.anter{
  position: fixed;
  bottom: 52px;
}
.thanksForParticipation {
  /*background-image: url("../assets/image/quessionnaire/title-bg.png");
  background-repeat: no-repeat;*/
  width: 78%;
  height: 76 / @rem;
  /*background-size: 100%;*/
  margin-top: 68 / @rem;
  color: #000;
  font-size: 26 / @rem;
  line-height: 76 / @rem;
  white-space: pre-wrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
<style>
.van-cell::after{
  border-bottom: 1px solid transparent !important;
}
[class*=van-hairline]::after{
  border: 0 solid transparent !important;
}
</style>
