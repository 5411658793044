<template>
  <div style="margin:0 20px">
    <div
      class="media-content"
      v-if="
        question != null && question.mediaType != '' && question.mediaUrl != ''
      "
    >
      <div v-if="question.mediaType == 1">
        <img class="full-width" :src="question.mediaUrl" />
      </div>
      <div v-if="question.mediaType == 2">
        <audio width="100%" type="video/mp4" controls>
          <source :src="question.mediaUrl"/>
        </audio>
      </div>
      <div v-if="question.mediaType == 3" class="media">
        <video type="video/mp4" width="100%" height="100%" controls disablepictureinpicture>
          <source :src="question.mediaUrl"/>
        </video>
      </div>
    </div>

    <div class="question">
      <div class="question-margin">
        <quessionnaire-tile :title="question"></quessionnaire-tile>
      </div>
      <div class="question-margin options">
        <van-checkbox-group v-model="answer" >
          <van-cell-group style="background-color: transparent;">
            <van-cell
              style="background-color: transparent;"
              v-for="(item, index) in question.options"
              clickable
              :key="index"
              :title="item.isOther?'Other':item.content"
              @click="toggle(item, index)"
            >
              <template #icon>
                <van-checkbox :name="item.id" ref="checkboxes" shape="square" checked-color="#333" style="margin-right: 16px;"></van-checkbox>
              </template>
            </van-cell>
            <van-cell
              v-if="isCheckOther"
              style="background-color: transparent;"
            >
              <van-field v-model="value" :placeholder="otherMess ? otherMess : 'Please input your options'" />
            </van-cell>
          </van-cell-group>
        </van-checkbox-group>
      </div>
    </div>
  </div>
</template>

<script>
import quessionnaireTile from "./questionnaireTitle.vue";
import _ from "lodash";

export default {
  components: { quessionnaireTile },
  props: { question: {}, list: {} },
  data() {
    return {
      answer: [],
      answerDetail: {
        answer: "",
        cascadeDetailCode: "",
        surveyOptionId: ""
      },
      isCheckOther: false,
      value: "",
      otherMess: ''
    };
  },
  methods: {
    toggle(option, index) {
      if (option.isOther == 1) {
        this.isCheckOther = true;
      }
      this.$refs.checkboxes[index].toggle();
      this.question.options.forEach(item=>{
        if(item.isOther){
          let indexShort = this.answer.includes(item.id)
          if(indexShort){
            if(option.id == item.id){
              this.isCheckOther = false;
            }else{
              this.isCheckOther = true;
            }
          }
        }
      })
    },
    answerQuestion() {
      this.answerDetail.surveyOptionId = this.answer;
      let answerDetails = [];
      // console.log("this.answer", this.answer);
      // this.answerDetail.answer = this.vaule 

      // for (let index in this.answer) {
      //   // adetail.surveyOptionId = this.answer[index];
      //   console.log("optionid", this.answer[index]);
      //   for (let i = 0; i < this.question.options.length; i++) {
      //     if (
      //       this.question.options[i].id == this.answer[index] &&
      //       this.question.isOther == 1
      //     ) {
      //       adetail.answer = this.value;
      //     }
      //     answerDetails.push({
      //       surveyOptionId: this.answer[index]
      //     });
      //   }
      // }
      for (let i = 0;i<this.answer.length;i++){
        for(let j = 0;j<this.question.options.length;j++){
          if(this.answer[i] === this.question.options[j].id){
            if(this.question.options[j].isOther === 1){
              answerDetails.push({surveyOptionId:this.answer[i], answer:this.value })
            }else{
              answerDetails.push({surveyOptionId:this.answer[i]})
            }
          }  
        }
      }
      answerDetails = _.uniqBy(answerDetails, "surveyOptionId");
      this.list[this.question.sort - 1] = {
        ...this.question,
        answer: answerDetails
      };
      // console.log("answerDetails", answerDetails);
      this.$emit(
        "answerQuestion",
        this.question.surveyId,
        answerDetails,
        this.list
      );
    }
  },
  watch:{
    question:{
      handler (v) {
        console.log(v)
        this.answer = []
        if(v.answer.length){
          v.answer.forEach(item => {
            this.answer.push(item.surveyOptionId)
          })
        }else{
          this.value = ''
        }
        let isAnswerIndex = -1
        v.options.forEach(item=>{
          if(item.isOther === 1){
            this.otherMess = item.content
            isAnswerIndex = v.answer.findIndex(itemd=>itemd.surveyOptionId === item.id)
            if(isAnswerIndex !== -1){
              this.value = v.answer[isAnswerIndex].content
            }else{
              this.value = ''
            }
          }
        })
        if(isAnswerIndex !== -1){
           this.isCheckOther = true
           this.value = v.answer[isAnswerIndex].answer
        }else{
          this.isCheckOther = false
          this.value = ''
        }
      },
      immediate: true,
      deep: true
    },
  },
  mounted() {
    // this.question.answer.filter(f => {
    //   this.answer.push(f.surveyOptionId);
    // });
    // console.log("this.question.answer", this.question.answer);
    // console.log("this.answer", this.answer);
  }
};
</script>

<style scoped>
.media-content {
  border-radius: 6px;
  width: 100%;
  margin-bottom: 10px;
}
.full-width {
  width: 100%;
  /*height: 160px;*/
}
.question {
  overflow: hidden;
  padding-bottom: 10px;
  border-radius: 6px;
  background: transparent;
}
.question .question-margin {
  margin: 10px 6px;
  padding-bottom: 10px;
}
.options{
  z-index: 100;
  max-height: 220px;
  overflow-y: auto;
  margin-top: 10px;
  padding-right: 3px;
}
.van-cell {
  padding: 10px 16px;
}
.media {
  width: 100%;
  position: relative;
  height: 160px;
}
</style>
