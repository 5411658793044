<template>
  <div style="margin:0 20px">
    <div
      class="media-content"
      v-if="
        question != null && question.mediaType != '' && question.mediaUrl != ''
      "
    >
      <div v-if="question.mediaType == 1">
        <img class="full-width" :src="question.mediaUrl" />
      </div>
      <div v-if="question.mediaType == 2">
       <audio width="100%" type="video/mp4" controls>
         <source :src="question.mediaUrl"/>
       </audio>
      </div>
      <div v-if="question.mediaType == 3" class="media">
        <video width="100%" height="100%" type="video/mp4" controls >
          <source :src="question.mediaUrl"/>
        </video>
      </div>
    </div>

    <div class="question">
      <div class="question-margin">
        <quessionnaire-tile :title="question"></quessionnaire-tile>
      </div>
      <div class="question-margin options">
        <van-checkbox-group v-model="answer">
          <van-cell-group>
            <van-cell
              style="background-color: rgb(242, 242, 242); margin-top:20px"
              v-for="(item,index) in question.options"
              clickable
              :key="index"
              @click="toggle(index,item)"
            >
              <img :src="item.content"  :class=" itemIdBox['itemId'+item.id] ? 'opacity-5':'' " style="width:200px" />
              <template #right-icon>
                <van-checkbox :name="item.id" ref="checkboxes" shape="square" checked-color="#BDAC9B"/>
              </template>
            </van-cell>
          </van-cell-group>
        </van-checkbox-group>
      </div>
    </div>
  </div>
</template>

<script>
import quessionnaireTile from "./questionnaireTitle.vue";

export default {
  components: { quessionnaireTile },
  props: { question: {}, list: {} },
  data() {
    return {
      answer: [],
      answerDetail: {
        answer: "",
        cascadeDetailCode: "",
        surveyOptionId: ""
      },
      itemIdBox:{}
    };
  },
  methods: {
    toggle(index,item) {
      this.$refs.checkboxes[index].toggle();
      for(let value in this.itemIdBox){
        this.$set(this.itemIdBox,value,true)
      }
    },
    answerQuestion() {
      let answerDetails = [];
      for (let i = 0; i < this.answer.length; i++) {
        let a = { ...this.answerDetail };
        a.surveyOptionId = this.answer[i];
        a.questionId = this.question.questionId
        answerDetails.push(a);
      }
      if(answerDetails.length){
        answerDetails = answerDetails.filter(item=>item.surveyOptionId !== -1)
      }
      this.question.answer = answerDetails;
      this.list[this.question.sort - 1] = {
        ...this.question,
        answer: answerDetails
      };
      this.$emit("answerQuestion", this.question.surveyId, answerDetails,this.list);
    }
  },
  watch:{
    answer:{
      handler(v){
        let that = this
        v.map(item=>{
          this.$set(this.itemIdBox,`itemId${item}`,false)
        })
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {
    this.question.answer.filter(f => {
      this.answer.push(f.surveyOptionId);
    });
    this.question.options.map((item)=>{
      this.$set(this.itemIdBox,`itemId${item.id}`,false)

    })
    console.log("this.question.answer", this.question.answer);
    console.log("this.answer", this.itemIdBox);
  }
};
</script>



<style scoped>
.media-content {
  border-radius: 6px;
  width: 100%;
  margin-bottom: 10px;
}
.full-width {
  width: 100%;
  /*height: 160px;*/
}
.question {
  overflow: hidden;
  padding-bottom: 10px;
  border-radius: 6px;
  background: transparent;
}
.question .question-margin {
  margin: 10px 6px;
  padding-bottom: 10px;
}
.options{
  max-height: 220px;
  overflow-y: auto;
  margin-top: 10px;
  padding-right: 3px;
}
.van-cell {
  padding: 10px 16px;
}
.media {
  width: 100%;
  position: relative;
  height: 160px;
}
</style>
